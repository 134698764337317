<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6" v-if="inspeccion_id">
                <h5 class="mb-0">Inspección: {{ inspeccion_id }}</h5>
              </div>
              <div class="col-sm-6" v-else>
                <h5 class="mb-0">Inspecciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hse</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Inspecciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- <div class="col-6 pt-2 pb-2">
                <h5>Inspecciones</h5>
              </div> -->
              <ul id="myTab" role="tablist" class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    class="nav-link active"
                    @click="cambiaTab('principal')"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-Gestion"
                    data-toggle="tab"
                    href="#Gestion"
                    class="nav-link"
                    @click="cambiaTab('gestiona')"
                    v-if="inspeccion_id"
                    >Gestionar Inspección</a
                  >
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <!-- Formulario -->
                <div id="Principal" class="tab-pane fade active show">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-2">
                        <label>Linea Negocio</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.linea_negocio"
                          :class="
                            $v.form.linea_negocio.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @change="changeLinea()"
                          :disabled="inspeccion_id || bloquear"
                        >
                          <option value="3">Tif</option>
                          <option value="4">Carga Seca</option>
                          <option value="5">Tep</option>
                          <option value="8">Hse</option>
                        </select>
                      </div>
                      <div
                        class="form-group col-md-2"
                        v-if="form.linea_negocio == 4"
                      >
                        <label>Tipo Servicio</label>
                        <v-select
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.tipo_servicio"
                          placeholder="Tipo Servicio"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.tipo_servicio"
                          :disabled="estado_documento == 2"
                          @input="resetData"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-4">
                        <label>Formato de Inspección</label>
                        <v-select
                          :class="[
                            $v.form.formato_inspeccion.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.formato_inspeccion"
                          placeholder="Formato Inspección"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.formatos_inspeccion"
                          :filterable="false"
                          :disabled="estado_documento == 2"
                          @search="buscarFormatosInspeccion"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="fecha_fin">Fecha vencimiento</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_v"
                          @change="validarFechaVenc()"
                          :disabled="estado_documento == 2"
                          :class="
                            $v.form.fecha_v.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="hora_fin">Hora vencimiento</label>
                        <input
                          v-if="form.linea_negocio"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_v"
                          :class="
                            $v.form.hora_v.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          readonly
                        />
                        <input
                          v-else
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_v"
                          :class="
                            $v.form.hora_v.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-2">
                        <label
                          v-if="form.linea_negocio == 4 && form.tipo_servicio"
                          >{{
                            form.tipo_servicio.numeracion == 1
                              ? "Vehículo"
                              : "Equipo"
                          }}</label
                        >
                        <label v-else>
                          Vehículo
                        </label>
                        <div v-if="form.linea_negocio != 8">
                          <v-select
                            :class="[
                              $v.form.cabezote.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                            ]"
                            v-model="form.cabezote"
                            placeholder="Placa"
                            label="placa"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.cabezotes"
                            :filterable="false"
                            :disabled="estado_documento == 2"
                            @search="buscarCabezotes"
                            @input="validaRemolque"
                          ></v-select>
                        </div>
                        <div v-else>
                          <input
                            :class="[
                              $v.form.placa_vehiculo.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            :disabled="estado_documento == 2 || bloquear"
                            v-model="form.placa_vehiculo"
                            class="form-control form-control-sm"
                            type="text"
                          />
                        </div>
                        <div v-if="form.cabezote">
                          <div
                            v-if="
                              form.cabezote.ultimos_registros &&
                                form.cabezote.ultimos_registros != ''
                            "
                          >
                            <span
                              class="badge badge-warning"
                              v-if="
                                form.cabezote.ultimos_registros[0]
                                  .fecha_hora_ultimo_reporte < fechaCorte
                              "
                            >
                              Ultimo reporte:
                              {{
                                form.cabezote.ultimos_registros[0]
                                  .fecha_hora_ultimo_reporte
                              }}
                            </span>
                            <span class="badge badge-success" v-else>
                              Ultimo reporte:
                              {{
                                form.cabezote.ultimos_registros[0]
                                  .fecha_hora_ultimo_reporte
                              }}
                            </span>
                          </div>
                          <div v-else>
                            <span class="badge badge-danger"
                              >No presenta fecha de reporte</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-group col-md-2"
                        v-if="
                          (form.linea_negocio != 8) & (form.linea_negocio != 5)
                        "
                      >
                        <label>Remolque</label>
                        <v-select
                          :class="[
                            form.cabezote && form.cabezote.tipo_vehiculo_id == 1
                              ? $v.form.remolque.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                              : '',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.remolque"
                          placeholder="Placa"
                          label="placa"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.remolques"
                          :filterable="false"
                          :disabled="
                            !form.cabezote ||
                              form.cabezote.tipo_vehiculo_id != 1 ||
                              estado_documento == 2
                          "
                          @search="buscarRemolques"
                        ></v-select>
                      </div>
                      <div
                        class="form-group col-md-4"
                        v-show="form.linea_negocio != 8"
                      >
                        <label>Empresa</label>
                        <v-select
                          :class="[
                            form.linea_negocio != 8 && $v.form.empresa.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.empresa"
                          placeholder="Empresa"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.empresas"
                          :disabled="estado_documento == 2"
                          @search="buscarEmpresas"
                        ></v-select>
                      </div>
                      <div
                        class="form-group col-md-4"
                        v-show="form.linea_negocio == 8"
                      >
                        <label>Empresa</label>
                        <v-select
                          :class="[
                            form.linea_negocio == 8 &&
                            $v.form.empresa_hse.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="empresa_hse2"
                          placeholder="Empresa Hse"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.empresasHse"
                          :disabled="estado_documento == 2 || bloquear"
                          @input="selectEmpresa"
                        ></v-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label for="fecha_inicio">Fecha Inicial</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_inicio"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="hora_inicio">Hora Inicial</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_inicio"
                          :class="
                            $v.form.hora_inicio.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="fecha_fin">Fecha Final</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_fin"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="hora_fin">Hora Final</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_fin"
                          :class="
                            $v.form.hora_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label>Responsable</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="responsable.nombre"
                          readonly
                        />
                        <input
                          v-model="form.responsable_id"
                          type="hidden"
                          class="form-control form-control-sm p-0"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Observaciones</label><br /><textarea
                          vtype="date"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="form.observaciones"
                          :disabled="estado_documento == 2"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Gestión de inspecciones -->
                <div
                  id="Gestion"
                  class="tab-pane fade"
                  v-if="inspeccion_id && formato_inspeccion"
                >
                  <HseInspeccionGestiona
                    ref="HseInspeccionGestiona"
                  ></HseInspeccionGestiona>
                  <HseInspeccionImages></HseInspeccionImages>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-md-3">
                    <button class="btn btn-lg bg-secondary" @click="back()">
                      <i class="fas fa-reply"></i><br />Volver
                    </button>
                    <button
                      class="btn btn-lg bg-primary"
                      v-show="
                        !$v.form.$invalid &&
                          tab == 'principal' &&
                          (estado_documento == 1 || estado_documento == null)
                      "
                      @click="validarOperacion"
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                    <button
                      class="btn btn-lg bg-primary"
                      v-if="estado_documento == 1 && tab == 'gestiona'"
                      @click="$refs.HseInspeccionGestiona.save()"
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                  </div>
                  <div
                    class="col-md-4"
                    v-if="
                      $store.getters.can('hse.inspecciones.edit') &&
                        estado_documento == 1 &&
                        tab == 'gestiona'
                    "
                  >
                    <label class="m-0">Estado:</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="
                        $refs.HseInspeccionGestiona.estadoForm.estado_documento
                      "
                      v-if="
                        $store.getters.can('hse.inspecciones.edit') &&
                          estado_documento == 1 &&
                          tab == 'gestiona'
                      "
                      @change="$refs.HseInspeccionGestiona.save()"
                      :disabled="
                        ($store.getters.can('hse.inspecciones.edit') &&
                          estado_documento != 1) ||
                          !$refs.HseInspeccionGestiona.flagGestionado
                      "
                    >
                      <option
                        v-for="estado in $refs.HseInspeccionGestiona.listaForm
                          .estados_documento"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import HseInspeccionGestiona from "./HseInspeccionGestiona";
import HseInspeccionImages from "./HseInspeccionImages";
import moment from "moment";
import { required, helpers } from "vuelidate/lib/validators";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "InspeccionForm",
  components: {
    vSelect,
    HseInspeccionGestiona,
    HseInspeccionImages,
  },
  data() {
    return {
      moment: moment,
      validar: 0,
      tab: "principal",
      bloquear: false,
      responsable: {
        id: this.$store.getters.getUser.id,
        nombre: this.$store.getters.getUser.name,
      },
      formato_inspeccion: null,
      detalles_inspecciones: null,
      inspeccion_id: null,
      fechaActual: null,
      fechaCorte: null,
      estado: null,
      estado_documento: null,
      viaje_id: null,
      viaje: null,
      viajeInspeccion: null,
      inspeccion: null,
      form: {
        linea_negocio: null,
        id: null,
        det_postulacion_id: null,
        fecha_v: null,
        hora_v: null,
        sitio_id: null,
        sitio_type: null,
        formato_inspeccion: null,
        cabezote: null,
        placa_vehiculo: null,
        remolque: null,
        empresa: null,
        empresa_hse: "",
        conductor: null,
        fecha_inicio: null,
        hora_inicio: null,
        fecha_fin: null,
        hora_fin: null,
        observaciones: null,
        responsable_id: this.$store.getters.getUser.id,
        tipo_servicio: null,
      },
      preoperacional: false,
      preoperacionalDisabled: false,
      listasForms: {
        sitios: [],
        puntos_gestion: [],
        formatos_inspeccion: [],
        empresas: [],
        conductores: [],
        cabezotes: [],
        remolques: [],
        tipo_servicio: [],
        empresasHse: [],
      },
      empresa_hse2: {},
    };
  },
  validations() {
    if (
      this.form.linea_negocio == 3 &&
      this.form.cabezote &&
      this.form.cabezote.tipo_vehiculo_id == 1
    ) {
      return {
        form: {
          linea_negocio: {
            required,
          },
          formato_inspeccion: {
            required,
          },
          cabezote: {
            required,
          },
          remolque: {
            required,
          },
          empresa: {
            required,
          },
          hora_v: {
            timeFormat,
            required,
          },
          hora_inicio: {
            timeFormat,
          },
          hora_fin: {
            timeFormat,
          },
          fecha_v: {
            required,
          },
        },
      };
    } else if (
      this.form.linea_negocio == 4 &&
      this.form.cabezote &&
      this.form.cabezote.tipo_vehiculo_id == 1
    ) {
      return {
        form: {
          linea_negocio: {
            required,
          },
          tipo_servicio: {
            required,
          },
          formato_inspeccion: {
            required,
          },
          cabezote: {
            required,
          },
          remolque: {
            required,
          },
          empresa: {
            required,
          },
          hora_v: {
            timeFormat,
            required,
          },
          hora_inicio: {
            timeFormat,
          },
          hora_fin: {
            timeFormat,
          },
          fecha_v: {
            required,
          },
        },
      };
    } else if (this.form.linea_negocio == 8) {
      return {
        form: {
          linea_negocio: {
            required,
          },
          formato_inspeccion: {
            required,
          },
          placa_vehiculo: {
            required,
          },
          empresa_hse: {
            required,
          },
          hora_v: {
            timeFormat,
            required,
          },
          fecha_v: {
            required,
          },
          hora_inicio: {
            timeFormat,
          },
          hora_fin: {
            timeFormat,
          },
        },
      };
    } else {
      return {
        form: {
          linea_negocio: {
            required,
          },
          formato_inspeccion: {
            required,
          },
          cabezote: {
            required,
          },
          empresa: {
            required,
          },
          hora_v: {
            timeFormat,
            required,
          },
          fecha_v: {
            required,
          },
          hora_inicio: {
            timeFormat,
          },
          hora_fin: {
            timeFormat,
          },
        },
      };
    }
  },
  methods: {
    async getIndex() {
      await this.getEmpresasHse();
      this.inspeccion_id = this.$route.params.inspeccion_id;
      let data = this.$route.params.data ? this.$route.params.data : null;
      this.form.empresa_hse = null;
      if (data) {
        this.bloquear = true;
        this.form.linea_negocio = 8;
        this.form.placa_vehiculo = data.placa_vehiculo;
        if (data.inspeccion_id) {
          this.inspeccion_id = data.inspeccion_id;
        } else {
          if (data.empresa_hse) {
            let emp_hse = this.listasForms.empresasHse.find(function(elem) {
              return elem.numeracion == data.empresa_hse;
            });
            if (emp_hse) {
              this.empresa_hse2 = emp_hse;
              this.form.empresa_hse = emp_hse.numeracion;
            }
          }
        }
      }

      //Fecha actual y se le suman 2 meses
      let fecha_actual = moment()
        .add(4, "M")
        .format("YYYY-MM-DD");
      this.form.fecha_v = fecha_actual;
      //Hora actual
      let hora_actual = moment().format("HH:mm:ss");
      this.form.hora_v = "23:59:59";

      if (this.inspeccion_id !== undefined) {
        await axios
          .get("/api/tif/inspecciones/show", {
            params: {
              id: this.inspeccion_id,
            },
          })
          .then((response) => {
            if (response.data.length > 0) {
              let result = response.data[0];
              this.estado = result.estado;
              this.estado_documento = result.estado_documento;
              this.formato_inspeccion = result.formato_inspeccion;
              this.detalles_inspecciones = result.detalles_inspecciones;

              // Se verifica el responsable de la inspección
              let responsable = this.form.responsable_id;
              if (result.responsable_id) {
                responsable = result.responsable.id;
                this.responsable = {
                  id: result.responsable.id,
                  nombre: result.responsable.name,
                };
              }

              // Se filtra el remolque
              let remolque = null;
              if (result.remolque) {
                remolque = {
                  id: result.remolque.id,
                  placa: result.remolque.placa,
                };
              }
              let linea_negocio = null;
              if (result.linea_negocio_id) {
                linea_negocio = result.linea_negocio_id;
              } else {
                linea_negocio = result.formato_inspeccion.linea_negocio_id;
              }

              let vh_equi = null;
              if (linea_negocio == 4) {
                // Se filtra el tipo de servicio
                const tip_serv = this.listasForms.tipo_servicio.find(function(
                  elem
                ) {
                  return elem.numeracion == result.vh_equi;
                });
                if (tip_serv) {
                  vh_equi = tip_serv;
                }
              }
              let emp_hse = null;
              let empresa_hse = null;
              if (result.empresa_hse) {
                emp_hse = this.listasForms.empresasHse.find(function(elem) {
                  return elem.numeracion == result.empresa_hse;
                });
                if (emp_hse) {
                  empresa_hse = emp_hse.numeracion;
                }
              }

              this.form = {
                linea_negocio,
                id: result.id,
                tipo_servicio: vh_equi ? vh_equi : null,
                formato_inspeccion: {
                  id: result.formato_inspeccion.id,
                  nombre: result.formato_inspeccion.nombre,
                },
                empresa: {
                  id: result.empresa ? result.empresa.id : null,
                  razon_social: result.empresa
                    ? result.empresa.razon_social
                    : null,
                },
                conductor: {
                  id: result.conductor ? result.conductor.id : null,
                  nombre: result.conductor
                    ? `${result.conductor.nombres} ${result.conductor.apellidos}`
                    : null,
                },
                cabezote: {
                  id: result.vehiculo ? result.vehiculo.id : null,
                  placa: result.vehiculo ? result.vehiculo.placa : null,
                  tipo_vehiculo_id: result.vehiculo
                    ? result.vehiculo.tipo_vehiculo_id
                    : null,
                },
                placa_vehiculo: result.placa_vehiculo,
                remolque,
                empresa_hse,
                fecha_inicio: moment(result.fecha_inicio).format("YYYY-MM-DD"),
                hora_inicio: result.hora_inicio,
                fecha_fin: result.fecha_fin,
                hora_fin: result.hora_fin,
                observaciones: result.observaciones,
                responsable_id: responsable,
                fecha_v: result.fecha_vencimiento,
                hora_v: result.hora_vencimiento,
              };
              this.empresa_hse2 = emp_hse;
              this.validaRemolque();
            }
          });
      }
    },

    async changeLinea() {
      this.limpiarCampos();
      await this.getIndex();
    },

    limpiarCampos() {
      this.form.id = null;
      this.form.det_postulacion_id = null;
      this.form.fecha_v = null;
      this.form.hora_v = null;
      this.form.sitio_id = null;
      this.form.sitio_type = null;
      this.form.formato_inspeccion = null;
      this.form.cabezote = null;
      this.form.remolque = null;
      this.form.empresa = null;
      this.form.empresa_hse = "";
      this.empresa_hse2 = {};
      this.form.conductor = null;
      this.form.fecha_inicio = null;
      this.form.hora_inicio = null;
      this.form.fecha_fin = null;
      this.form.hora_fin = null;
      this.form.observaciones = null;
      this.form.responsable_id = this.$store.getters.getUser.id;
      this.form.tipo_servicio = null;

      this.listasForms.formatos_inspeccion = [];
      this.listasForms.cabezotes = [];
      this.listasForms.remolques = [];
      this.viaje = null;
    },

    resetData() {
      this.listasForms.empresas = [];
      this.listasForms.conductores = [];
      this.listasForms.cabezotes = [];
      this.listasForms.remolques = [];
      this.form.conductor = null;
      this.form.cabezote = null;
      this.form.remolque = null;
    },

    getTiposServicios() {
      axios.get("/api/lista/130").then((response) => {
        this.listasForms.tipo_servicio = response.data;
      });
    },

    buscarSitios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/tif/inspecciones/sitios?lugar=" + search;
        axios
          .get(url, {})
          .then(function(response) {
            me.listasForms.sitios = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarFormatosInspeccion(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/tif/inspecciones/formatos";
        axios
          .get(url, {
            params: {
              nombre: search,
              linea_negocio: this.form.linea_negocio,
            },
          })
          .then(function(response) {
            me.listasForms.formatos_inspeccion = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarCabezotes(search, loading) {
      if (search != "") {
        let operacion = 0;
        let me = this;
        loading(true);
        var url = "api/tif/inspecciones/buscarcabezote";
        if (
          this.form.tipo_servicio &&
          this.form.tipo_servicio.numeracion != 1
        ) {
          url = "api/tif/inspecciones/buscarequipo";
        }

        axios
          .get(url, {
            params: {
              placa: search,
              linea_negocio: me.form.linea_negocio,
            },
          })
          .then(function(response) {
            me.listasForms.cabezotes = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarRemolques(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/tif/inspecciones/buscarremolque?placa=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.remolques = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarEmpresas(search, loading) {
      this.listasForms.conductores = [];
      this.form.conductor = [];
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/tif/inspecciones/empresa";
        axios
          .get(url, {
            params: {
              razon_social: search,
              linea_negocio: this.form.linea_negocio,
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async getEmpresasHse() {
      await axios.get("/api/lista/164").then((response) => {
        this.listasForms.empresasHse = response.data;
      });
    },

    async selectEmpresa() {
      if (this.empresa_hse2) {
        this.form.empresa_hse = await this.empresa_hse2.numeracion;
      } else {
        this.form.empresa_hse = null;
      }
    },

    validarFechaVenc() {
      let fecha_actual = moment()
        .add(4, "M")
        .format("YYYY-MM-DD");
      if (this.form.fecha_v > fecha_actual) {
        this.$swal({
          icon: "error",
          title: "La fecha de vencimiento no puede superar los 4 meses",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.fecha_v = fecha_actual;
      }
    },

    validaRemolque() {
      if (!this.form.cabezote || this.form.cabezote.tipo_vehiculo_id != 1) {
        this.form.remolque = null;
      } else if (this.form.cabezote.viajes) {
        this.form.viaje_id = this.form.cabezote.viajes.id;
        // Se llenan los datos del viaje
        this.viaje = {
          sitio_origen: this.form.cabezote.viajes.det_guia.sitio_origen.nombre,
          sitio_destino: this.form.cabezote.viajes.det_guia.sitio_destino
            .nombre,
          despachado_a: this.form.cabezote.viajes.det_guia.despachado_a,
        };
        // Se llenan los datos del remolque
        if (this.form.cabezote.viajes.det_guia.remolque) {
          this.form.remolque = {
            id: this.form.cabezote.viajes.det_guia.remolque.id,
            placa: this.form.cabezote.viajes.det_guia.remolque.placa,
          };
        }

        // Se llenan los datos de la empresa
        if (this.form.cabezote.viajes.det_guia.transportadora) {
          this.form.empresa = {
            id: this.form.cabezote.viajes.det_guia.transportadora.id,
            razon_social: this.form.cabezote.viajes.det_guia.transportadora
              .razon_social,
          };
        }

        // Se llenan los datos del conductor
        if (this.form.cabezote.viajes.det_guia.conductor) {
          this.form.conductor = {
            id: this.form.cabezote.viajes.det_guia.conductor.id,
            nombre: `${this.form.cabezote.viajes.det_guia.conductor.nombres} ${this.form.cabezote.viajes.det_guia.conductor.apellidos}`,
          };
        }
      }
    },

    validarOperacion() {
      let data = this.getData();
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/inspecciones/validarInspeccion",
        params: data,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        this.save();
      });
    },

    save() {
      this.cargando = true;
      if (this.validar == "si") {
        this.$swal({
          icon: "error",
          title: `Ya existe una inspección con el mismo vehículo, remolque, conductor y fecha y/o hora vencimiento..`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.cabezote = {};
        this.form.conductor = {};
        this.form.remolque = {};
        this.form.fecha_v = null;
        this.form.hora_v = null;
        this.cargando = false;
      } else if (!this.$v.form.$invalid) {
        let data = this.getData();
        axios({
          method: "PUT",
          url: "/api/tif/inspecciones",
          data: data,
        })
          .then((response) => {
            this.$route.params.inspeccion_id = response.data.id;
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Los datos de la inspección se guardaron exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "" + e.response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getData() {
      // Remolque
      let remolque_id = null;
      if (this.form.remolque) {
        remolque_id = this.form.remolque.id;
      }

      // Se valida la fecha y hora de inicio de la insspección
      let fecha_inicio = this.form.fecha_inicio;
      let hora_inicio = this.form.hora_inicio;
      if (!this.form.fecha_inicio && !this.form.hora_inicio) {
        let ahora = new Date();
        let fecha = `${ahora.getFullYear()}-${ahora.getMonth() +
          1}-${ahora.getDate()}`;
        let hora = `${ahora.getHours()}:${ahora.getMinutes()}:${ahora.getSeconds()}`;
        fecha_inicio = fecha;
        hora_inicio = hora;
      }

      let vehiculo_type = null;
      let conductor_type = null;
      if (this.form.linea_negocio == 4) {
        if (
          this.form.tipo_servicio &&
          this.form.tipo_servicio.numeracion == 1
        ) {
          vehiculo_type = `App\\Vehiculo`;
          conductor_type = `App\\Conductor`;
        } else {
          vehiculo_type = `App\\Equipo`;
          conductor_type = `App\\Operario`;
        }
      } else {
        vehiculo_type = `App\\Vehiculo`;
        conductor_type = `App\\Conductor`;
      }

      let data = {
        linea_negocio_id: this.form.linea_negocio,
        id: this.form.id,
        vh_equi: this.form.tipo_servicio
          ? this.form.tipo_servicio.numeracion
          : null,
        formato_inspeccion_id: this.form.formato_inspeccion.id,
        tif_sitio_id: this.form.sitio_id,
        empresa_id: this.form.empresa ? this.form.empresa.id : null,
        empresa_hse: this.form.empresa_hse,
        conductor_type,
        conductor_id: this.form.conductor ? this.form.conductor.id : null,
        vehiculo_type,
        vehiculo_id: this.form.cabezote ? this.form.cabezote.id : null,
        placa_vehiculo: this.form.placa_vehiculo,
        remolque_id,
        fecha_inicio,
        hora_inicio,
        fecha_fin: this.form.fecha_fin,
        fecha_vencimiento: this.form.fecha_v,
        hora_vencimiento: this.form.hora_v,
        estado: 1,
        estado_documento: 1,
        hora_fin: this.form.hora_fin,
        observaciones: this.form.observaciones,
        responsable_id: this.form.responsable_id,
      };
      return data;
    },

    cambiaTab(tab) {
      this.tab = tab;
    },

    back() {
      return this.$router.replace("/Hse/Inspecciones");
    },
  },

  mounted() {
    this.getTiposServicios();
    this.getIndex();
  },
};
</script>
